import React from 'react';

const Roles = ({plan, design, develop, test, launch, maintain}) => {
    return (
    <>
        <div className="phases">
            <ul>
                <li><div className={`${plan ? 'bg-primary' : 'disabled'}`}><i class="bi bi-lightbulb"></i><span className="ms-1 ms-lg-2">Plan</span></div></li>
                <li><div className={`${design ? 'bg-success' : 'disabled'}`}><i class="bi bi-heart"></i><span className="ms-1 ms-lg-2">Design</span></div></li>
                <li><div className={`${develop ? 'bg-info' : 'disabled'}`}><i class="bi bi-code-slash"></i><span className="ms-1 ms-lg-2">Develop</span></div></li>
                <li><div className={`${test ? 'bg-warning' : 'disabled'}`}><i class="bi bi-clipboard-check"></i><span className="ms-1 ms-lg-2">Test</span></div></li>
                <li><div className={`${launch ? 'bg-orange' : 'disabled'}`}><i class="bi bi-rocket-takeoff"></i><span className="ms-1 ms-lg-2">Launch</span></div></li>
                <li><div className={`${maintain ? 'bg-purple' : 'disabled'}`}><i class="bi bi-infinity"></i><span className="ms-1 ms-lg-2">Maintain</span></div></li>
            </ul>
        </div>
  </>
  )
};

export default Roles;
