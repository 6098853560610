import React from 'react';
import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Slide } from "react-awesome-reveal";
import { Link } from 'wouter';
import "../styles/skills.scss"

export const SkillsParallax = () => {
    const background: BannerLayer = {
        shouldAlwaysCompleteAnimation: true,
        speed: -15,
        children: (
          <div className="skills-image"></div>
        )
      };

  return (
    <ParallaxBanner
        layers={[background]}
        className="skills-container"
    >
        <Container fluid="lg">
            <div className="section-cards">
                <Row>
                    <Col xl={{ span: 4, offset: 2 }} lg={{ span: 5, offset: 1}} sm={6}>
                        <Slide direction="left" className="h-100">
                        <Card className="skills-card h-100">
                            <Card.Body>
                                <Card.Title as="h2" className="mb-0">Portfolio</Card.Title>
                            </Card.Body>
                            <Card.Img variant="top" src="/img/home/curated.png" />
                            <Card.Body>
                                <Card.Text>
                                    To save you from the information overload, I've created a curated selection of visuals from my career.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-transparent border-0">
                                <div className="hide-dark"><Link href="/Portfolio"><Button variant="danger" className="w-100">See the visuals</Button></Link></div>
                                <div className="show-dark"><Link href="/Portfolio"><Button variant="primary" className="w-100">See the visuals</Button></Link></div>
                            </Card.Footer>
                        </Card>
                        </Slide>
                    </Col>
                    <Col xl={4} lg={5} sm={6}>
                        <Slide direction="right" className="h-100">
                        <Card className="skills-card h-100 mt-4 mt-sm-0">
                            <Card.Body>
                                <Card.Title as="h2" className="mb-0">Case Studies</Card.Title>
                            </Card.Body>
                            <div className="hide-dark"><Card.Img variant="top" src="/img/home/case-studies-lm.png" /></div>
                            <div className="show-dark"><Card.Img variant="top" src="/img/home/case-studies-dm.png" /></div>
                            <Card.Body>
                                <Card.Text>
                                    I've also put together a few case studies detailing the results from my most recent or relevant endeavors.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-transparent border-0">
                                <div className="hide-dark"><Link href="/CaseStudies"><Button variant="danger" className="w-100">See the results</Button></Link></div>
                                <div className="show-dark"><Link href="/CaseStudies"><Button variant="primary" className="w-100">See the results</Button></Link></div>
                            </Card.Footer>
                        </Card>
                        </Slide>
                    </Col>
                </Row>
            </div>
        </Container>
    </ParallaxBanner>
  );
};
