import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function KSAlertExample() {
    const [show, setShow] = useState(true);
  
    if (show) {
      return (
        <>
        <Alert>This is a plain alert. It defaults to primary.</Alert>
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            Change this and that and try again. Duis mollis, est non commodo
            luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            Cras mattis consectetur purus sit amet fermentum.
          </p>
        </Alert>
        {[
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
      ].map((variant) => (
        <Alert key={variant} variant={variant} dismissible>
            <Alert.Heading>Hey, nice to see you</Alert.Heading>
            This is a {variant} alert with{' '}
            <Alert.Link href="#">an example link</Alert.Link>. Give it a click if
            you like.
        </Alert>
      ))}
        </>
      );
    }
    return <Button onClick={() => setShow(true)}>Show Alert</Button>;
  }

export default KSAlertExample;