import React from 'react';
import CountUp from "react-countup";
import '../styles/stat.scss';

const Stat = ({ label, value, delay }) => {
    
    return (
        <div className="d-flex w-100 align-items-center text-center stat">
            <div className="w-100">
                <div className="stat-value">
                    <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={delay}
                        scrollSpyOnce={true}
                        start={0}
                        end={value}
                        duration={2}
                        useEasing={true}
                        separator=","
                    >
                        {({ countUpRef }) => <span ref={countUpRef} />}
                    </CountUp>
                </div>
                <div className="stat-label">{label}</div>
            </div>
        </div>
    );
};

export default Stat;
