import React from 'react';

const AboutBlurb = () => {
  return  <div className="about-blurb">
            <h1>Launching <em>excellence</em> is my passion.</h1>
            <p className="mt-3 mb-0">When building a product or leading a team, I strive to launch <em>potential</em> into <em>excellence</em>&hellip; and beyond.</p>
          </div>;
};

export default AboutBlurb;
