import React from 'react';

const HeroBlurb = () => {
  return  <div className="hero-blurb">
            <h1><em>Empathy</em> is my superpower.</h1>
            <p className="mt-3 mb-0">It is the key to providing a rewarding user experience, to communicating and connecting with people in various roles, and to effectively guiding a team with respect and honesty.</p>
          </div>;
};

export default HeroBlurb;
