import React from "react";
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

const customAnimation = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`;

export default function AnimateProgressBar({ children }) {
    return <Reveal keyframes={customAnimation}>{children}</Reveal>;
}


