import React from 'react';

const ClientLogos = () => {
  return (
    <>
        <div className="slide"><img src="/img/logo-squares/logo-2waves.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-5cnw.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-100p.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-459.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-5800.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-acn.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-adminicamp.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ameriplan.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ampegy.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ams.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/amvescap0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-arbonne.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/arlington0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-as.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-aschere.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ask.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/beck0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/mcteer-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/bos-0.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-bccsi.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-bft.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-bjh.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-blackink.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-bookingninja.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-brainfood.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-bre.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/btc-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-bullitt.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/wealth-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-camelot.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-cc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-cdi.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-chj.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-chpa.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-cleannation.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-coa.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/cpt0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/cresa0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-csc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-csi.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-cwt.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-dawg.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/daydots0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/dcss-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-dmg.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-dog.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/doral0.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-dpi.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-dynamo.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-e4s.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-ebby.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/edge0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/efpr-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ems.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-enlyten.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/epersp0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/epicentric0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-epiq.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-equiplanet.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-evolv.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/ey0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/eyi-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tom.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-fantasydraft.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-fcb.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-fed.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/fedtech-00.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-fff.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-fhtm.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/fire0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/forever0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-freedom.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-freeenergy.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/frit-00.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/fuel0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-gc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-goyikes.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-greenlight.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/hboc0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-hh.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-hi5.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-hiview.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-hoblitzelle.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/hpb0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-hv.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-icon.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-idten.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-iea.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ignite.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/itoc-0.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/jci-full0.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-kb.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/knetic-000.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-launchgirl.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-lloret.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-lsi.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-luxurilife.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-m4m.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-mannatech.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-mm.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-momentis.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-momentus.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/m6power0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-mt.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-myteltag.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-nbc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/newsclips-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/nucentrix0.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-okc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-paycation.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-pbs.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-pfb.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-phc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ple.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/ppb0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-praeco.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-primeaxs.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/ppai0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/ppai-webby0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/pa-00.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-rags.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-rbclife.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-rg.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/rr-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-russ.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-salvationarmy.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-sb.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/shipco0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/sierra0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-sma.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-smg.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-socketoutlet.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-stream.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/summerfield0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tcec.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-team.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-telemar.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-televerus.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/tgf0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-travellight.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-traverus.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-truconnect.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/tsc-0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-ts.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tsp.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tspmlc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tspins.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tspsucc.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tspsupp.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-tt.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-unplug.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/uw-00.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-vemma.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-verus.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-viridian.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/logo-vzn.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-wwe.jpg" alt="" /></div>
        <div className="slide bmoc"><img src="/img/logo-squares/wyndham0.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-xodus.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-xoom.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-xstream.jpg" alt="" /></div>
        <div className="slide"><img src="/img/logo-squares/logo-zurvita.jpg" alt="" /></div>        
    </>
  );
};

export default ClientLogos;
