import React from 'react';

const StudiesBlurb = () => {
  return  <div className="studies-blurb">
            <h1>Results that <em>empower</em> others.</h1>
            <p className="mt-3 mb-0">I work hard to empower teams and users to reach <span className="text-nowrap">their goals.</span></p>
          </div>;
};

export default StudiesBlurb;
