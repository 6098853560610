import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import KSFormExample from './KSFormExample';
import KSAlertExample from './KSAlertExample';
import KSToastExample from './KSToastExample';
import './KitchenSink.scss';


const KitchenSink = () => {
return (
    <>
        <header className="bd-header bg-secondary py-2 d-flex align-items-stretch">
            <Container fluid className="d-flex align-items-center">
                <h1 className="d-flex align-items-center fs-4 text-white mb-0">
                    <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo-white.svg" width="38" height="30" className="me-3" alt="Bootstrap"/>
                    Cheatsheet
                </h1>
                <a href="#" className="ms-auto">Link Example</a>
            </Container>
        </header>

        <Container fluid className="bd-cheatsheet bg-body pt-5">
            <section id="content">
                <h1 className="pt-4">Content</h1>

                <article className="my-5" id="colors">
                    <div className="bd-heading align-self-start">
                        <h2>Background Colors</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/utilities/background/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example row">
                            <div className="p-1  col-6 mb-0 bg-dark">.bg-dark</div>
                            <div className="p-1  col-6 mb-0 bg-dark-subtle text-dark-emphasis">.bg-dark-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-secondary">.bg-secondary</div>
                            <div className="p-1  col-6 mb-0 bg-secondary-subtle">.bg-secondary-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-primary">.bg-primary</div>
                            <div className="p-1  col-6 mb-0 bg-primary-subtle">.bg-primary-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-cyan">.bg-cyan</div>
                            <div className="p-1  col-6 mb-0 bg-cyan-subtle">.bg-cyan-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-success">.bg-success</div>
                            <div className="p-1  col-6 mb-0 bg-success-subtle">.bg-success-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-green">.bg-green</div>
                            <div className="p-1  col-6 mb-0 bg-green-subtle">.bg-green-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-lime">.bg-lime</div>
                            <div className="p-1  col-6 mb-0 bg-lime-subtle">.bg-warning-lime</div>
                            <div className="p-1  col-6 mb-0 bg-info">.bg-info</div>
                            <div className="p-1  col-6 mb-0 bg-info-subtle">.bg-info-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-warning">.bg-warning</div>
                            <div className="p-1  col-6 mb-0 bg-warning-subtle">.bg-warning-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-orange">.bg-orange</div>
                            <div className="p-1  col-6 mb-0 bg-orange-subtle">.bg-orange-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-danger">.bg-danger</div>
                            <div className="p-1  col-6 mb-0 bg-danger-subtle">.bg-danger-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-pink">.bg-pink</div>
                            <div className="p-1  col-6 mb-0 bg-pink-subtle">.bg-pink-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-purple">.bg-purple</div>
                            <div className="p-1  col-6 mb-0 bg-purple-subtle">.bg-purple-subtle</div>
                            <div className="p-1  col-6 mb-0 bg-indigo">.bg-indigo</div>
                            <div className="p-1  col-6 mb-0 bg-indigo-subtle">.bg-indigo-subtle</div>
                            
                            <div className="p-1  col-6 mb-0 bg-light">.bg-light</div>
                            <div className="p-1  col-6 mb-0 bg-light-subtle text-light-emphasis">.bg-light-subtle</div>


                            <div className="p-1  col-6 mb-0 bg-transparent text-body">.bg-transparent</div><div className="p-1  col-6 mb-0"></div>
                            <div className="p-1  col-6 mb-0 bg-body text-body">.bg-body</div><div className="p-1  col-6 mb-0"></div>
                            <div className="p-1  col-6 mb-0 bg-body-secondary">.bg-body-secondary</div><div className="p-1  col-6 mb-0"></div>
                            <div className="p-1  col-6 mb-0 bg-body-tertiary">.bg-body-tertiary</div><div className="p-1  col-6 mb-0"></div>
                            

                            <div className="p-1  col-6 mb-0 bg-white">.bg-white</div><div className="p-1  col-6 mb-0"></div>
                            <div className="p-1  col-6 mb-0 bg-black">.bg-black</div><div className="p-1  col-6 mb-0"></div>

                        </div>
                        
                        <div className="bd-example row">
                            <div className="p-1  mb-0 bg-white">.bg-white</div>
                            <div className="p-1  mb-0 bg-gray-100">.bg-gray-100</div>
                            <div className="p-1  mb-0 bg-gray-200">.bg-gray-200</div>
                            <div className="p-1  mb-0 bg-gray-300">.bg-gray-300</div>
                            <div className="p-1  mb-0 bg-gray-400">.bg-gray-400</div>
                            <div className="p-1  mb-0 bg-gray-500">.bg-gray-500</div>
                            <div className="p-1  mb-0 bg-gray-600">.bg-gray-600</div>
                            <div className="p-1  mb-0 bg-gray-700">.bg-gray-700</div>
                            <div className="p-1  mb-0 bg-gray-800">.bg-gray-800</div>
                            <div className="p-1  mb-0 bg-gray-900">.bg-gray-900</div>
                            <div className="p-1  mb-0 bg-black">.bg-black</div>
                        </div>
                    </div>
                </article>


                <article className="my-5" id="typography">
                    <div className="bd-heading align-self-start">
                        <h2>Typography</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/content/typography/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <p className="display-1">Display 1</p>
                        <p className="display-2">Display 2</p>
                        <p className="display-3">Display 3</p>
                        <p className="display-4">Display 4</p>
                        <p className="display-5">Display 5</p>
                        <p className="display-6">Display 6</p>
                        </div>
                
                        <div className="bd-example">
                        <p className="h1">LAUNCH<em>Girl</em></p>
                        <p className="h1">Heading <em>One</em></p>
                        <p className="h2">Heading <em>Two</em></p>
                        <p className="h3">Heading <em>Three</em></p>
                        <p className="h4">Heading <em>Four</em></p>
                        <p className="h5">Heading <em>Five</em></p>
                        <p className="h6">Heading <em>Six</em></p>
                        </div>
                
                        <div className="bd-example">
                        <p className="lead">
                        This is a lead paragraph. It stands out from regular paragraphs.
                        </p>
                        </div>
                
                        <div className="bd-example">
                        <p>You can use the mark tag to <mark>highlight</mark> text.</p>
                        <p><del>This line of text is meant to be treated as deleted text.</del></p>
                        <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
                        <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
                        <p><u>This line of text will render as underlined.</u></p>
                        <p><small>This line of text is meant to be treated as fine print.</small></p>
                        <p><strong>This line rendered as bold text.</strong></p>
                        <p><em>This line rendered as italicized text.</em></p>
                        </div>
                
                        <div className="bd-example">
                        <blockquote className="blockquote">
                        <p>A well-known quote, contained in a blockquote element.</p>
                        <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                        </blockquote>
                        </div>
                
                        <div className="bd-example">
                        <ul className="list-unstyled">
                        <li>This is a list.</li>
                        <li>It appears completely unstyled.</li>
                        <li>Structurally, it's still a list.</li>
                        <li>However, this style only applies to immediate child elements.</li>
                        <li>Nested lists:
                            <ul>
                            <li>are unaffected by this style</li>
                            <li>will still show a bullet</li>
                            <li>and have appropriate left margin</li>
                            </ul>
                        </li>
                        <li>This may still come in handy in some situations.</li>
                        </ul>
                        </div>
                
                        <div className="bd-example">
                        <ul className="list-inline">
                        <li className="list-inline-item">This is a list item.</li>
                        <li className="list-inline-item">And another one.</li>
                        <li className="list-inline-item">But they're displayed inline.</li>
                        </ul>
                        </div>
                    </div>
                </article>
                
                <article className="my-5" id="images">
                <div className="bd-heading align-self-start">
                    <h2>Images</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/content/images/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">
                        <Image src="https://picsum.photos/1900/300" fluid alt=""/>            
                    </div>
            
                    <div className="bd-example">
                        <Image src="https://picsum.photos/200" thumbnail alt=""/>
                        <Image src="https://picsum.photos/200" rounded alt="" className='mx-3'/>
                        <Image src="https://picsum.photos/200" roundedCircle alt=""/>            
                    </div>

                    
                    <div className="bd-example">
                        <div className="logo-icon logo-lg"></div><br/>
                        <div className="logo logo-lg"></div>
                    </div>
                </div>
                </article>
                
                <article className="my-5" id="tables">
                <div className="bd-heading align-self-start">
                    <h2>Tables</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/content/tables/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>1</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                </tr>
                                <tr>
                                <td>2</td>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                </tr>
                                <tr>
                                <td>3</td>
                                <td colSpan={2}>Larry the Bird</td>
                                <td>@twitter</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="bd-example">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>1</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                </tr>
                                <tr>
                                <td>2</td>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                </tr>
                                <tr>
                                <td>3</td>
                                <td colSpan={2}>Larry the Bird</td>
                                <td>@twitter</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
            
                    <div className="bd-example">
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td colSpan={2}>Larry the Bird</td>
                            <td>@twitter</td>
                            </tr>
                        </tbody>
                    </Table>
                    </div>

                    <div className="bd-example">
                        <Table striped="columns">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>1</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                </tr>
                                <tr>
                                <td>2</td>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className="bd-example">
                    <Table responsive>
                        <thead>
                            <tr>
                            <th>#</th>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <th key={index}>Table heading</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>2</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>3</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                        </tbody>
                    </Table>
                    </div>

                    <div className='bd-example'>
                        <Table responsive="sm" className='mb-3'>
                        <thead>
                            <tr>
                            <th>#</th>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <th key={index}>Table heading</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>2</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>3</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                        </tbody>
                        </Table>
                        <Table responsive="md" className='mb-3'>
                        <thead>
                            <tr>
                            <th>#</th>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <th key={index}>Table heading</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>2</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>3</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                        </tbody>
                        </Table>
                        <Table responsive="lg" className='mb-3'>
                        <thead>
                            <tr>
                            <th>#</th>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <th key={index}>Table heading</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>2</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>3</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                        </tbody>
                        </Table>
                        <Table responsive="xl" className='mb-3'>
                        <thead>
                            <tr>
                            <th>#</th>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <th key={index}>Table heading</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>2</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                            <tr>
                            <td>3</td>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                            </tr>
                        </tbody>
                        </Table>
                    </div>
            
                    <div className="bd-example">
                    <Table>
                    <thead>
                    <tr>
                        <th scope="col">Class</th>
                        <th scope="col">Heading</th>
                        <th scope="col">Heading</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Default</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    
                    <tr className="table-primary">
                        <th scope="row">Primary</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-secondary">
                        <th scope="row">Secondary</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-success">
                        <th scope="row">Success</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-danger">
                        <th scope="row">Danger</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-warning">
                        <th scope="row">Warning</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-info">
                        <th scope="row">Info</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-light">
                        <th scope="row">Light</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    <tr className="table-dark">
                        <th scope="row">Dark</th>
                        <td>Cell</td>
                        <td>Cell</td>
                    </tr>
                    </tbody>
                    </Table>
                    </div>
            

                </div>
                </article>
                
                <article className="my-5" id="figures">
                <div className="bd-heading align-self-start">
                    <h2>Figures</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/content/figures/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">
                    <figure className="figure">
                        <svg height="auto" viewBox="0 0 2000 1000" xmlns="http://www.w3.org/2000/svg"><path d="M0 1000h80M100 1000h80v-31.309q0-4-4-4h-72q-4 0-4 4ZM200 1000h80v-68.322q0-4-4-4h-72q-4 0-4 4ZM300 1000h80V866.134q0-4-4-4h-72q-4 0-4 4ZM400 1000h80V820.051q0-4-4-4h-72q-4 0-4 4ZM500 1000h80V783.712q0-4-4-4h-72q-4 0-4 4ZM600 1000h80V701.471q0-4-4-4h-72q-4 0-4 4ZM700 1000h80V710.301q0-4-4-4h-72q-4 0-4 4ZM800 1000h80V580.118q0-4-4-4h-72q-4 0-4 4ZM900 1000h80V576.762q0-4-4-4h-72q-4 0-4 4ZM1000 1000h80V558.854q0-4-4-4h-72q-4 0-4 4ZM1100 1000h80V535.23q0-4-4-4h-72q-4 0-4 4ZM1200 1000h80V644.898q0-4-4-4h-72q-4 0-4 4ZM1300 1000h80V332.99q0-4-4-4h-72q-4 0-4 4ZM1400 1000h80V380.596q0-4-4-4h-72q-4 0-4 4ZM1500 1000h80V506.946q0-4-4-4h-72q-4 0-4 4ZM1600 1000h80V157.914q0-4-4-4h-72q-4 0-4 4ZM1700 1000h80V380.544q0-4-4-4h-72q-4 0-4 4ZM1800 1000h80V503.643q0-4-4-4h-72q-4 0-4 4ZM1900 1000h80V28.22q0-4-4-4h-72q-4 0-4 4Z" fill="#00d688"/></svg>
                        <figcaption className="figure-caption">A caption for the above image.</figcaption>
                    </figure>

                    </div>
                </div>
                </article>
            </section>
            
            <section id="forms">
                <h1 className="pt-3">Forms</h1>
            
                <article className="my-5" id="overview">
                <div className="bd-heading align-self-start">
                    <h2>Overview</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/overview/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">

                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text>
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="inputPassword5">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                aria-describedby="passwordHelpBlock"
                            />
                            <Form.Text id="passwordHelpBlock">
                                Your password must be 8-20 characters long, contain letters and numbers,
                                and must not contain spaces, special characters, or emoji.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Select Field</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Label>Added without Map</Form.Label>
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group>

                        {['checkbox', 'radio'].map((type) => (
                            <div key={`default-${type}`}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Added via Mapping</Form.Label>
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        label={`default ${type}`}
                                    />
                                </Form.Group>
                            </div>
                        ))}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Example textarea</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>



                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Default file input example</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Multiple files input example</Form.Label>
                            <Form.Control type="file" multiple />
                        </Form.Group>
                        <Form.Group controlId="formFileDisabled" className="mb-3">
                            <Form.Label>Disabled file input example</Form.Label>
                            <Form.Control type="file" disabled />
                        </Form.Group>


                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Small file input example</Form.Label>
                            <Form.Control type="file" size="sm" />
                        </Form.Group>
                        <Form.Group controlId="formFileLg" className="mb-3">
                            <Form.Label>Large file input example</Form.Label>
                            <Form.Control type="file" size="lg" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Check this switch"
                            />
                            <Form.Check // prettier-ignore
                                disabled
                                type="switch"
                                label="disabled switch"
                                id="disabled-custom-switch"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label>
                            <Form.Control
                                type="color"
                                id="exampleColorInput"
                                defaultValue="#563d7c"
                                title="Choose your color"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Range</Form.Label>
                            <Form.Range />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Button variant="primary" type="submit">Submit</Button>
                        </Form.Group>


                    </Form>
                    </div>
                </div>
                </article>

                <article className="my-5" id="inlineforms">
                <div className="bd-heading align-self-start">
                    <h2>Inline Forms & Grid Forms</h2>
                </div>
            
                <div>
                    <div className="bd-example">

                    <h3>Inline Radios/Checkboxes Using Row/Col</h3>
                    <Form>

                        {['checkbox', 'radio'].map((type) => (
                            <Form.Group key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="1"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                />
                                <Form.Check
                                    inline
                                    label="2"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                />
                                <Form.Check
                                    inline
                                    disabled
                                    label="3 (disabled)"
                                    type={type}
                                    id={`inline-${type}-3`}
                                />
                            </Form.Group>
                        ))}
                    </Form>

                    <h3 className='mt-4'>Label-less Form Using Row/Col</h3>
                    <Form>
                    <Row>
                        <Col>
                        <Form.Control placeholder="First name" />
                        </Col>
                        <Col>
                        <Form.Control placeholder="Last name" />
                        </Col>
                    </Row>
                    </Form>

                    <h3 className='mt-4'>Stacked Form Using Row/Col</h3>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control placeholder="1234 Main St" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control placeholder="Apartment, studio, or floor" />
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue="Choose...">
                                <option>Choose...</option>
                                <option>Texas</option>
                            </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>

                    <h3 className='mt-4'>Inline Form Using Row/Col</h3>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={2}>
                            Email
                            </Form.Label>
                            <Col sm={10}>
                            <Form.Control type="email" placeholder="Email" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={2}>
                            Password
                            </Form.Label>
                            <Col sm={10}>
                            <Form.Control type="password" placeholder="Password" />
                            </Col>
                        </Form.Group>
                        <fieldset>
                            <Form.Group as={Row} className="mb-3">
                            <Form.Label as="legend" column sm={2}>
                                Radios
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Check
                                type="radio"
                                label="first radio"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios1"
                                />
                                <Form.Check
                                type="radio"
                                label="second radio"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios2"
                                />
                                <Form.Check
                                type="radio"
                                label="third radio"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios3"
                                />
                            </Col>
                            </Form.Group>
                        </fieldset>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                            <Col sm={{ span: 10, offset: 2 }}>
                            <Form.Check label="Remember me" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Sign in</Button>
                            </Col>
                        </Form.Group>
                    </Form>

                    <h3 className='mt-4'>Inline Form Label Sizing</h3>
                    <Form>
                    <Row>
                        <Form.Label column="lg" lg={2}>
                        Large Text
                        </Form.Label>
                        <Col>
                        <Form.Control size="lg" type="text" placeholder="Large text" />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Form.Label column lg={2}>
                        Normal Text
                        </Form.Label>
                        <Col>
                        <Form.Control type="text" placeholder="Normal text" />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Form.Label column="sm" lg={2}>
                        Small Text
                        </Form.Label>
                        <Col>
                        <Form.Control size="sm" type="text" placeholder="Small text" />
                        </Col>
                    </Row>
                    </Form>

                    <h3 className='mt-4'>Inline Form Column Sizing</h3>
                    <Form>
                    <Row>
                        <Col xs={7}>
                        <Form.Control placeholder="City" />
                        </Col>
                        <Col>
                        <Form.Control placeholder="State" />
                        </Col>
                        <Col>
                        <Form.Control placeholder="Zip" />
                        </Col>
                    </Row>
                    </Form>

                    <h3 className='mt-4'>Inline Form Auto Sizing</h3>
                    <Form>
                    <Row className="align-items-center">
                        <Col xs="auto">
                        <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Name
                        </Form.Label>
                        <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Jane Doe"
                        />
                        </Col>
                        <Col xs="auto">
                        <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                            Username
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Control id="inlineFormInputGroup" placeholder="Username" />
                        </InputGroup>
                        </Col>
                        <Col xs="auto">
                        <Form.Check
                            type="checkbox"
                            id="autoSizingCheck"
                            className="mb-2"
                            label="Remember me"
                        />
                        </Col>
                        <Col xs="auto">
                        <Button type="submit" className="mb-2">
                            Submit
                        </Button>
                        </Col>
                    </Row>
                    </Form>

                    <h3 className='mt-4'>Inline Form Mixed Sizing</h3>
                    <Form>
                    <Row className="align-items-center">
                        <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                            Name
                        </Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Jane Doe" />
                        </Col>
                        <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                            Username
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Control
                            id="inlineFormInputGroupUsername"
                            placeholder="Username"
                            />
                        </InputGroup>
                        </Col>
                        <Col xs="auto" className="my-1">
                        <Form.Check
                            type="checkbox"
                            id="autoSizingCheck2"
                            label="Remember me"
                        />
                        </Col>
                        <Col xs="auto" className="my-1">
                        <Button type="submit">Submit</Button>
                        </Col>
                    </Row>
                    </Form>

                    </div>
                </div>
                </article>

                <article className="my-5" id="disabled-forms">
                <div className="bd-heading align-self-start">
                    <h2>Disabled forms</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/overview/#disabled-forms">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">
                    <Form>


                        <Form.Group className="mb-3">
                            <Form.Label>Disabled input</Form.Label>
                            <Form.Control placeholder="Disabled input" disabled />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Disabled Read Only  Input</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Disabled Readonly input"
                                aria-label="Disabled Readonly input example"
                                disabled
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Read Only Input</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Readonly input"
                                aria-label="Readonly input example"
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Disabled select menu</Form.Label>
                            <Form.Select disabled>
                            <option>Disabled select</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Disabled checkbox</Form.Label>
                            <Form.Check type="checkbox" label="Disabled checkbox" disabled />
                        </Form.Group>

                        {['checkbox', 'radio'].map((type) => (
                            <div key={`default-${type}`}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Added via Mapping</Form.Label>
                                    <Form.Check
                                        disabled
                                        type={type}
                                        label={`disabled ${type}`}
                                        id={`disabled-default-${type}`}
                                    />
                                </Form.Group>
                            </div>
                        ))}

                        <fieldset disabled>
                            <legend>Disabled fieldset</legend>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Disabled input</Form.Label>
                                <Form.Control id="disabledTextInput" placeholder="Disabled input" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Disabled select menu</Form.Label>
                                <Form.Select id="disabledSelect">
                                    <option>Disabled select</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="disabledFieldsetCheck"
                                    label="Can't check this"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Button type="button">Disabled Fieldset Submit</Button>
                            </Form.Group>
                        </fieldset>


                        <Form.Group controlId="formFileDisabled" className="mb-3">
                            <Form.Label>Disabled file input example</Form.Label>
                            <Form.Control type="file" disabled />
                        </Form.Group>

                        <Form.Group className="mb-3">  
                        <Form.Check // prettier-ignore
                            disabled
                            type="switch"
                            label="disabled switch"
                            id="disabled-custom-switch"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3"> 
                            <Form.Label>Range</Form.Label>
                            <Form.Range  disabled/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Button variant="primary" type="submit" disabled>
                                Disabled Submit
                            </Button>
                        </Form.Group>


                    </Form>
                    </div>
                </div>
                </article>

                <article className="my-5" id="sizing">
                <div className="bd-heading align-self-start">
                    <h2>Field Sizing</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/form-control/#sizing">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">

                        <Form.Group controlId="formFileLg" className="mb-3">
                            <Form.Label>Large file input example with label</Form.Label>
                            <Form.Control type="file" size="lg" />
                        </Form.Group>
                        <Form.Group controlId="formFileNormal" className="mb-3">
                            <Form.Label>Normal file input example with label</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Small file input example with label</Form.Label>
                            <Form.Control type="file" size="sm" />
                        </Form.Group>
                        
                        

                        <Form.Control size="lg" type="text" placeholder="Large text" />
                        <br />
                        <Form.Control type="text" placeholder="Normal text" />
                        <br />
                        <Form.Control size="sm" type="text" placeholder="Small text" />
                        <br />
                        <Form.Select size="lg">
                            <option>Large select</option>
                        </Form.Select>
                        <br />
                        <Form.Select>
                            <option>Default select</option>
                        </Form.Select>
                        <br />
                        <Form.Select size="sm">
                            <option>Small select</option>
                        </Form.Select>
                        <br />

                    </div>
            
                </div>
                </article>

                <article className="my-5" id="input-group">
                <div className="bd-heading align-self-start">
                    <h2>Input group</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/input-group/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">

                    <Form>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                        <Form.Control
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text>
                    </InputGroup>

                    <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                        https://example.com/users/
                        </InputGroup.Text>
                        <Form.Control id="basic-url" aria-describedby="basic-addon3" />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control aria-label="Amount (to the nearest dollar)" />
                        <InputGroup.Text>.00</InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text>With textarea</InputGroup.Text>
                        <Form.Control as="textarea" aria-label="With textarea" />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <Form.Control aria-label="Text input with checkbox" />
                    </InputGroup>

                    <InputGroup>
                        <InputGroup.Radio aria-label="Radio button for following text input" />
                        <Form.Control aria-label="Text input with radio button" />
                    </InputGroup>


                    </Form>

                    </div>
                </div>
                </article>

                <article className="my-5" id="floating-labels">
                <div className="bd-heading align-self-start">
                    <h2>Floating labels</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/floating-labels/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">
                    <Form>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="mb-3"
                        >
                            <Form.Control type="email" placeholder="name@example.com" />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Password">
                            <Form.Control type="password" placeholder="Password" />
                        </FloatingLabel>
                    </Form>
                    </div>
                </div>
                </article>
                
                <article className="my-5" id="validation">
                <div className="bd-heading align-self-start">
                    <h2>Validation</h2>
                    <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/forms/validation/">Documentation</a>
                </div>
            
                <div>
                    <div className="bd-example">

                    <KSFormExample />

                    </div>
                </div>
                </article>
            </section>
            
            <section id="components">
                <h1 className="pt-3">Components</h1>
            
                <article className="my-5" id="accordion">
                    <div className="bd-heading align-self-start">
                        <h2>Accordion</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/accordion/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Accordion Item #1</Accordion.Header>
                                <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        </div>
                    </div>
                </article>
                
                <article className="my-5" id="alerts">
                    <div className="bd-heading align-self-start">
                        <h2>Alerts</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/alerts/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">                        
                            <KSAlertExample />
                        </div>
                    </div>
                </article>

                <article className="my-5" id="badge">
                    <div className="bd-heading align-self-start">
                        <h2>Badge</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/badge/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                            <h1>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h1>
                            <h2>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h2>
                            <h3>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h3>
                            <h4>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h4>
                            <h5>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h5>
                            <h6>
                                Example heading <Badge bg="secondary">New</Badge>
                            </h6>
                        </div>
                
                        <div className="bd-example">
                            <Stack direction="horizontal" gap={2}>
                                <Badge bg="primary">Primary</Badge>
                                <Badge bg="secondary">Secondary</Badge>
                                <Badge bg="success">Success</Badge>
                                <Badge bg="danger">Danger</Badge>
                                <Badge bg="warning" text="dark">Warning</Badge>
                                <Badge bg="info">Info</Badge>
                                <Badge bg="light" text="dark">Light</Badge>
                                <Badge bg="dark">Dark</Badge>
                            </Stack>
                        </div>
                
                        <div className="bd-example">
                            <Stack direction="horizontal" gap={2}>
                                <Badge bg="transparent" className="border border-primary text-primary">Primary</Badge>
                                <Badge bg="transparent" className="border border-secondary text-secondary">Secondary</Badge>
                                <Badge bg="transparent" className="border border-success text-success">Success</Badge>
                                <Badge bg="transparent" className="border border-danger text-danger">Danger</Badge>
                                <Badge bg="transparent" className="border border-warning text-warning">Warning</Badge>
                                <Badge bg="transparent" className="border border-info text-info">Info</Badge>
                                <Badge bg="transparent" className="border border-light text-light">Light</Badge>
                                <Badge bg="transparent" className="border border-dark text-dark">Dark</Badge>
                            </Stack>
                        </div>

                        <div className="bd-example">
                            <Stack direction="horizontal" gap={2}>
                                <Badge bg="glow" className="border border-primary">Primary</Badge>
                                <Badge bg="glow" className="border border-secondary">Secondary</Badge>
                                <Badge bg="glow" className="border border-success">Success</Badge>
                                <Badge bg="glow" className="border border-danger">Danger</Badge>
                                <Badge bg="glow" className="border border-warning">Warning</Badge>
                                <Badge bg="glow" className="border border-info">Info</Badge>
                                <Badge bg="glow" className="border border-light">Light</Badge>
                                <Badge bg="glow" className="border border-dark">Dark</Badge>
                            </Stack>
                        </div>

                        <div className="bd-example">
                            <Stack direction="horizontal" gap={2}>
                                <Badge pill bg="primary">Primary</Badge>
                                <Badge pill bg="secondary">Secondary</Badge>
                                <Badge pill bg="success">Success</Badge>
                                <Badge pill bg="danger">Danger</Badge>
                                <Badge pill bg="warning" text="dark">Warning</Badge>
                                <Badge pill bg="info">Info</Badge>
                                <Badge pill bg="light" text="dark">Light</Badge>
                                <Badge pill bg="dark">Dark</Badge>
                            </Stack>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="breadcrumb">
                    <div className="bd-heading align-self-start">
                        <h2>Breadcrumb</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/breadcrumb/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <nav ariaLabel="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Library</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Data</li>
                        </ol>
                        </nav>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="buttons">
                    <div className="bd-heading align-self-start">
                        <h2>Buttons</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/buttons/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        
                        <button type="button" className="btn btn-primary">Primary</button>
                        <button type="button" className="btn btn-secondary">Secondary</button>
                        <button type="button" className="btn btn-success">Success</button>
                        <button type="button" className="btn btn-danger">Danger</button>
                        <button type="button" className="btn btn-warning">Warning</button>
                        <button type="button" className="btn btn-info">Info</button>
                        <button type="button" className="btn btn-light">Light</button>
                        <button type="button" className="btn btn-dark">Dark</button>
                
                        <button type="button" className="btn btn-link">Link</button>
                        </div>
                
                        <div className="bd-example">
                        <button type="button" className="btn btn-outline-primary">Primary</button>
                        <button type="button" className="btn btn-outline-secondary">Secondary</button>
                        <button type="button" className="btn btn-outline-success">Success</button>
                        <button type="button" className="btn btn-outline-danger">Danger</button>
                        <button type="button" className="btn btn-outline-warning">Warning</button>
                        <button type="button" className="btn btn-outline-info">Info</button>
                        <button type="button" className="btn btn-outline-light">Light</button>
                        <button type="button" className="btn btn-outline-dark">Dark</button>
                        </div>

                        <div className="bd-example">
                            <button disabled type="button" className="btn btn-primary">Primary</button>
                            <button disabled type="button" className="btn btn-secondary">Secondary</button>
                            <button disabled type="button" className="btn btn-success">Success</button>
                            <button disabled type="button" className="btn btn-danger">Danger</button>
                            <button disabled type="button" className="btn btn-warning">Warning</button>
                            <button disabled type="button" className="btn btn-info">Info</button>
                            <button disabled type="button" className="btn btn-light">Light</button>
                            <button disabled type="button" className="btn btn-dark">Dark</button>                
                            <button disabled type="button" className="btn btn-link">Link</button>
                        </div>
                        <div className="bd-example">
                            <button disabled type="button" className="btn btn-outline-primary">Primary</button>
                            <button disabled type="button" className="btn btn-outline-secondary">Secondary</button>
                            <button disabled type="button" className="btn btn-outline-success">Success</button>
                            <button disabled type="button" className="btn btn-outline-danger">Danger</button>
                            <button disabled type="button" className="btn btn-outline-warning">Warning</button>
                            <button disabled type="button" className="btn btn-outline-info">Info</button>
                            <button disabled type="button" className="btn btn-outline-light">Light</button>
                            <button disabled type="button" className="btn btn-outline-dark">Dark</button>
                        </div>
                
                        <div className="bd-example">
                        <button type="button" className="btn btn-primary btn-sm">Small button</button>
                        <button type="button" className="btn btn-primary">Standard button</button>
                        <button type="button" className="btn btn-primary btn-lg">Large button</button>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="button-group">
                    <div className="bd-heading align-self-start">
                        <h2>Button group</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/button-group/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <div className="btn-toolbar" role="toolbar" ariaLabel="Toolbar with button groups">
                        <div className="btn-group me-2" role="group" ariaLabel="First group">
                            <button type="button" className="btn btn-secondary">1</button>
                            <button type="button" className="btn btn-secondary">2</button>
                            <button type="button" className="btn btn-secondary">3</button>
                            <button type="button" className="btn btn-secondary">4</button>
                        </div>
                        <div className="btn-group me-2" role="group" ariaLabel="Second group">
                            <button type="button" className="btn btn-secondary">5</button>
                            <button type="button" className="btn btn-secondary">6</button>
                            <button type="button" className="btn btn-secondary">7</button>
                        </div>
                        <div className="btn-group" role="group" ariaLabel="Third group">
                            <button type="button" className="btn btn-secondary">8</button>
                        </div>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="card">
                    <div className="bd-heading align-self-start">
                        <h2>Card</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/card/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <div className="row  row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card bg-glow mb-3">
                                <div className="card-header">
                                    Featured
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>

                            <div className="card">
                                <img src="https://picsum.photos/600/150" className="card-img-top" alt="card image"/>
                    
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                            <div className="card">
                            <div className="card-header">
                                Featured
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Card title</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <a href="javascript:void(0);" className="btn btn-primary w-100">In Card Body</a>
                            </div>
                            <div className="card-footer p-0">
                                <a href="javascript:void(0);" className="btn btn-primary w-100 rounded-top-0">In Card Footer</a>
                            </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Card title</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">An item</li>
                                <li className="list-group-item">A second item</li>
                                <li className="list-group-item">A third item</li>
                            </ul>
                            <div className="card-body">
                                <a href="#" className="card-link">Card link</a>
                                <a href="#" className="card-link">Another link</a>
                            </div>
                            <div className="card-footer">
                                2 days ago
                            </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                            <div className="row g-0">
                                <div className="col-md-4">
                                <img src="https://picsum.photos/380/350" className="img-fluid rounded-start" alt="card image"/>
                
                                </div>
                                <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <p className="card-text"><small>Last updated 3 mins ago</small></p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="carousel">
                    <div className="bd-heading align-self-start">
                        <h2>Carousel</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/carousel/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <Carousel>
                            <Carousel.Item>
                                <img src="https://picsum.photos/900/300" className="d-block w-100" alt="card image"/>
                                <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src="https://picsum.photos/900/300" className="d-block w-100" alt="card image"/>
                                <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src="https://picsum.photos/900/300" className="d-block w-100" alt="card image"/>
                                <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>

                        </div>
                    </div>
                </article>

                <article className="my-5" id="dropdowns">
                    <div className="bd-heading align-self-start">
                        <h2>Dropdowns</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/dropdowns/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <div className="btn-group">
                        <div className="dropdown">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropdownMenuButtonSM">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropdownMenuButton">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button className="btn btn-secondary btn-lg dropdown-toggle" type="button" id="dropdownMenuButtonLG" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropdownMenuButtonLG">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        </div>
                        </div>
                
                        <div className="bd-example">
                        <div className="btn-group">
                        <button type="button" className="btn btn-primary">Primary</button>
                        <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        <div className="btn-group">
                        <button type="button" className="btn btn-secondary">Secondary</button>
                        <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        <div className="btn-group">
                        <button type="button" className="btn btn-success">Success</button>
                        <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        <div className="btn-group">
                        <button type="button" className="btn btn-info">Info</button>
                        <button type="button" className="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        <div className="btn-group">
                        <button type="button" className="btn btn-warning">Warning</button>
                        <button type="button" className="btn btn-warning dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        <div className="btn-group">
                        <button type="button" className="btn btn-danger">Danger</button>
                        <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </div>
                        </div>
                
                        <div className="bd-example">
                        <div className="btn-group">
                        <div className="dropend">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropendMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropend button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropendMenuButton">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        <div className="dropup">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropupMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropup button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropupMenuButton">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        <div className="dropstart">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropstartMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropstart button
                            </button>
                            <ul className="dropdown-menu" ariaLabelledby="dropstartMenuButton">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        </div>
                        </div>
                
                        <div className="bd-example">
                        <div className="btn-group">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownRightMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            End-aligned menu
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" ariaLabelledby="dropdownRightMenuButton">
                            <li><h6 className="dropdown-header">Dropdown header</h6></li>
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                        </div>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="list-group">
                    <div className="bd-heading align-self-start">
                        <h2>List group</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/list-group/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <ul className="list-group">
                        <li className="list-group-item disabled" ariaDisabled="true">A disabled item</li>
                        <li className="list-group-item">A second item</li>
                        <li className="list-group-item">A third item</li>
                        <li className="list-group-item">A fourth item</li>
                        <li className="list-group-item">And a fifth one</li>
                        </ul>
                        </div>
                
                        <div className="bd-example">
                        <ul className="list-group list-group-flush">
                        <li className="list-group-item">An item</li>
                        <li className="list-group-item">A second item</li>
                        <li className="list-group-item">A third item</li>
                        <li className="list-group-item">A fourth item</li>
                        <li className="list-group-item">And a fifth one</li>
                        </ul>
                        </div>
                
                        <div className="bd-example">
                        <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">A simple default list group item</a>
                        
                        <a href="#" className="list-group-item list-group-item-action list-group-item-primary">A simple primary list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-secondary">A simple secondary list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-success">A simple success list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-danger">A simple danger list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-warning">A simple warning list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-info">A simple info list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-light">A simple light list group item</a>
                        <a href="#" className="list-group-item list-group-item-action list-group-item-dark">A simple dark list group item</a>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="modal">
                    <div className="bd-heading align-self-start">
                        <h2>Modal</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/modal/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <div className="d-flex justify-content-between flex-wrap">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                            Launch demo modal
                        </button>
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdropLive">
                            Launch static backdrop modal
                        </button>
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenteredScrollable">
                            Vertically centered scrollable modal
                        </button>
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalFullscreen">
                            Full screen
                        </button>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="navs">
                    <div className="bd-heading align-self-start">
                        <h2>Navs</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/navs-tabs/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <nav className="nav">
                        <a className="nav-link active" aria-current="page" href="#">Active</a>
                        <a className="nav-link" href="#">Link</a>
                        <a className="nav-link" href="#">Link</a>
                        <a className="nav-link disabled" href="#" tabindex="-1" ariaDisabled="true">Disabled</a>
                        </nav>
                        </div>
                
                        <div className="bd-example">
                        <nav>
                        <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
                        </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" ariaLabelledby="nav-home-tab">
                            <p><strong>This is some placeholder content the Home tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" ariaLabelledby="nav-profile-tab">
                            <p><strong>This is some placeholder content the Profile tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                        </div>
                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" ariaLabelledby="nav-contact-tab">
                            <p><strong>This is some placeholder content the Contact tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                        </div>
                        </div>
                        </div>
                
                        <div className="bd-example">
                        <ul className="nav nav-pills">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Active</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled" href="#" tabindex="-1" ariaDisabled="true">Disabled</a>
                        </li>
                        </ul>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="navbar">
                    <div className="bd-heading align-self-start">
                        <h2>Navbar</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/navbar/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                            <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo-white.svg" width="38" height="30" className="d-inline-block align-top" alt="Bootstrap" loading="lazy"/>
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" ariaLabel="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                                </li>
                                <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu" ariaLabelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link disabled" href="#" tabindex="-1" ariaDisabled="true">Disabled</a>
                                </li>
                            </ul>
                            <form className="d-flex">
                                <input className="form-control me-2" type="search" placeholder="Search" ariaLabel="Search"/>
                                <button className="btn btn-outline-dark" type="submit">Search</button>
                            </form>
                            </div>
                        </div>
                        </nav>
                
                        <nav className="navbar navbar-expand-lg navbar-dark bg-primary mt-5">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                            <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo-white.svg" width="38" height="30" className="d-inline-block align-top" alt="Bootstrap" loading="lazy"/>
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" ariaLabel="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                                </li>
                                <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu" ariaLabelledby="navbarDropdown2">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link disabled" href="#" tabindex="-1" ariaDisabled="true">Disabled</a>
                                </li>
                            </ul>
                            <form className="d-flex">
                                <input className="form-control me-2" type="search" placeholder="Search" ariaLabel="Search"/>
                                <button className="btn btn-outline-light" type="submit">Search</button>
                            </form>
                            </div>
                        </div>
                        </nav>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="pagination">
                    <div className="bd-heading align-self-start">
                        <h2>Pagination</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/pagination/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <nav ariaLabel="Pagination example">
                        <ul className="pagination pagination-sm">
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active" aria-current="page">
                            <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                        </ul>
                        </nav>
                        </div>
                
                        <div className="bd-example">
                        <nav ariaLabel="Standard pagination example">
                        <ul className="pagination">
                            <li className="page-item">
                            <a className="page-link" href="#" ariaLabel="Previous">
                                <span aria-hidden="true">«</span>
                            </a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                            <a className="page-link" href="#" ariaLabel="Next">
                                <span aria-hidden="true">»</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                        </div>
                
                        <div className="bd-example">
                        <nav ariaLabel="Another pagination example">
                        <ul className="pagination pagination-lg flex-wrap">
                            <li className="page-item disabled">
                            <a className="page-link" href="#" tabindex="-1" ariaDisabled="true">Previous</a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active" aria-current="page">
                            <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                        </nav>
                        </div>
                    </div>
                </article>
                
                <article className="my-5" id="popovers">
                    <div className="bd-heading align-self-start">
                        <h2>Popovers</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/popovers/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <button type="button" className="btn btn-lg btn-danger" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?" data-bs-original-title="Popover title">Click to toggle popover</button>
                        </div>
                
                        <div className="bd-example">
                        <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-bs-original-title="" title="">
                        Popover on top
                        </button>
                        <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-bs-original-title="" title="">
                        Popover on end
                        </button>
                        <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-bs-original-title="" title="">
                        Popover on bottom
                        </button>
                        <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-bs-original-title="" title="">
                        Popover on start
                        </button>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="progress">
                    <div className="bd-heading align-self-start">
                        <h2>Progress</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/progress/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        <div className="progress mb-3">
                        <div className="progress-bar" role="progressbar" ariaValuenow="0" ariaValuemin="0" ariaValuemax="100">0%</div>
                        </div>
                        <div className="progress mb-3">
                        <div className="progress-bar bg-success w-25" role="progressbar" ariaValuenow="25" ariaValuemin="0" ariaValuemax="100">25%</div>
                        </div>
                        <div className="progress mb-3">
                        <div className="progress-bar bg-info text-dark w-50" role="progressbar" ariaValuenow="50" ariaValuemin="0" ariaValuemax="100">50%</div>
                        </div>
                        <div className="progress mb-3">
                        <div className="progress-bar bg-warning text-dark w-75" role="progressbar" ariaValuenow="75" ariaValuemin="0" ariaValuemax="100">75%</div>
                        </div>
                        <div className="progress">
                        <div className="progress-bar bg-danger w-100" role="progressbar" ariaValuenow="100" ariaValuemin="0" ariaValuemax="100">100%</div>
                        </div>
                        </div>
                
                        <div className="bd-example">
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: '15%' }} ariaValuenow="15" ariaValuemin="0" ariaValuemax="100"></div>
                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style={{ width: '40%' }} ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="scrollspy">
                    <div className="bd-heading align-self-start">
                        <h2>Scrollspy</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/scrollspy/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                            <nav id="navbar-example2" className="navbar navbar-light bg-light px-3">
                                <a className="navbar-brand" href="#">Navbar</a>
                                <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#scrollspyHeading1">First</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#scrollspyHeading2">Second</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Dropdown</a>
                                    <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#scrollspyHeading3">Third</a></li>
                                    <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                                    </ul>
                                </li>
                                </ul>
                            </nav>
                            <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabindex="0">
                                <h4 id="scrollspyHeading1">First heading</h4>
                                <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p>
                                <h4 id="scrollspyHeading2">Second heading</h4>
                                <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p>
                                <h4 id="scrollspyHeading3">Third heading</h4>
                                <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p>
                                <h4 id="scrollspyHeading4">Fourth heading</h4>
                                <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p>
                                <h4 id="scrollspyHeading5">Fifth heading</h4>
                                <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="spinners">
                    <div className="bd-heading align-self-start">
                        <h2>Spinners</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/spinners/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                        
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        </div>
                
                        <div className="bd-example">
                        
                        <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        </div>
                    </div>
                </article>

                <article className="my-5" id="toasts">
                    <div className="bd-heading align-self-start">
                        <h2>Toasts</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/toasts/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example">
                            <KSToastExample />
                        </div>
                    </div>
                </article>

                <article className="mt-3 mb-5 pb-5" id="tooltips">
                    <div className="bd-heading align-self-start">
                        <h2>Tooltips</h2>
                        <a className="d-flex align-items-center" href="https://getbootstrap.com/docs/5.0/components/tooltips/">Documentation</a>
                    </div>
                
                    <div>
                        <div className="bd-example tooltip-demo">
                        <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">Tooltip on top</button>
                        <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on end">Tooltip on end</button>
                        <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom">Tooltip on bottom</button>
                        <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="left" title="Tooltip on start">Tooltip on start</button>
                        <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-html="true" title="<em>Tooltip</em> <u>with</u> <b>HTML</b>">Tooltip with HTML</button>
                        </div>
                    </div>
                </article>
            </section>
        </Container>
        
        <div className="modal fade" id="exampleModalDefault" tabindex="-1" ariaLabelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" ariaLabel="Close"></button>
            </div>
            <div className="modal-body">
                ...
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div>
        <div className="modal fade" id="staticBackdropLive" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" ariaLabelledby="staticBackdropLiveLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLiveLabel">Modal title</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" ariaLabel="Close"></button>
            </div>
            <div className="modal-body">
                <p>I will not close if you click outside me. Don't even try to press escape key.</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Understood</button>
            </div>
            </div>
        </div>
        </div>
        <div className="modal fade" id="exampleModalCenteredScrollable" tabindex="-1" ariaLabelledby="exampleModalCenteredScrollableTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenteredScrollableTitle">Modal title</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" ariaLabel="Close"></button>
            </div>
            <div className="modal-body">
                <p>This is some placeholder content to show the scrolling behavior for modals. We use repeated line breaks to demonstrate how content can exceed minimum inner height, thereby showing inner scrolling. When content becomes longer than the prefedined max-height of modal, content will be cropped and scrollable within the modal.</p>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <p>This content should appear at the bottom after you scroll.</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div>
        <div className="modal fade" id="exampleModalFullscreen" tabindex="-1" ariaLabelledby="exampleModalFullscreenLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title h4" id="exampleModalFullscreenLabel">Full screen modal</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" ariaLabel="Close"></button>
            </div>
            <div className="modal-body">
                ...
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>
      

    </>
);
};

export default KitchenSink;
