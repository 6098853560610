import React from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import ContactIcons from './ContactIcons';
import "../styles/footer.scss";

const Footer = () => {
  const getCurrentYear = () => { return new Date().getFullYear();};

  return (
    <Container fluid id="footer">
      <footer>
        <Row className="py-1">
          <Col sm={8} className="text-center text-sm-start">
          &copy; {getCurrentYear()} Tonya Abna. All rights reserved.
          </Col>
          <Col sm={4} className="text-center text-sm-end">
            <ContactIcons />
          </Col>
        </Row>
      </footer>
    </Container>
  );
};

export default Footer;
