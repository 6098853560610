import React, { useState } from 'react';
import { PhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import Roles from '../components/Roles.jsx';

const photos = [

{ src: "/img/screenshots/insights-customer.png", width: 990, height: 1354, description: <><Roles plan design develop test launch maintain /></> },
{ src: "/img/screenshots/insights-dashboard.png", width: 990, height: 1124, description: <><Roles plan design test launch /></> },
{ src: "/img/screenshots/ticket-page.png", width: 990, height: 2677, description: <><Roles plan design /></> },
{ src: "/img/screenshots/tips-and-tricks.png", width: 1970, height: 1690, description: <><Roles plan design develop test launch maintain /></> },
{ src: "/img/screenshots/tsp-login.png", width: 990, height: 640, description: <><Roles plan design launch /></> },
{ src: "/img/screenshots/gcf.png", width: 990, height: 1575, description: <><Roles plan design develop test launch maintain /></> },

{ src: "/img/screenshots/wayfinding.png", width: 1400, height: 983, description: <><Roles plan design  /></> },
{ src: "/img/screenshots/manage-team.png", width: 1200, height: 2177, description: <><Roles plan design test /></> },
{ src: "/img/screenshots/vzlearn-search.png", width: 1200, height: 678, description: <><Roles plan design test /></> },
{ src: "/img/screenshots/about-you.png", width: 1200, height: 1886, description: <><Roles plan design test launch /></> },
{ src: "/img/screenshots/digital-assistant.png", width: 659, height: 1603, description: <><Roles plan design test launch /></> },
{ src: "/img/screenshots/apis.png", width: 1200, height: 1688, description: <><Roles plan design test /></> },
{ src: "/img/screenshots/vzlearn-results.png", width: 1200, height: 2194, description: <><Roles plan design test launch /></> },

{ src: "/img/screenshots/primeaxs.png", width: 960, height: 740, description: <><Roles plan design develop test /></> },
      { src: "/img/screenshots/stones.jpg", width: 990, height: 1014, description: <><Roles plan design develop test /></> },
      { src: "/img/screenshots/rangers.jpg", width: 990, height: 1014, description: <><Roles plan design develop test /></> },
      { src: "/img/screenshots/primeaxs-mobile.png", width: 2000, height: 1032, description: <><Roles plan design develop test /></> },
{ src: "/img/screenshots/angeltree.png", width: 1024, height: 3582, description: <><Roles plan design develop test launch maintain /></> },
{ src: "/img/screenshots/salvation-army.png", width: 990, height: 1674, description: <><Roles plan design develop test launch maintain /></> },
{ src: "/img/screenshots/fantasy-draft.png", width: 1280, height: 1785, description: <><Roles plan design develop test /></> },
      { src: "/img/screenshots/fantasy-draft-mobile.png", width: 1600, height: 563, description: <><Roles plan design develop test /></> },
{ src: "/img/screenshots/trekorda.png", width: 990, height: 3047, description: <><Roles plan design develop /></> },
{ src: "/img/screenshots/compass.png", width: 2000, height: 407, description: <><Roles plan design develop test launch maintain /></> },
{ src: "/img/screenshots/myteltag.png", width: 990, height: 537, description: <><Roles plan design develop launch /></> },


{ src: "/img/screenshots/breast-cancer-can-stick-it.png", width: 845, height: 836, description: <><Roles design /></> },
{ src: "/img/screenshots/fight-like-a-girl.png", width: 1099, height: 927, description: <><Roles design /></> },
{ src: "/img/screenshots/got-bake-sale.png", width: 1300, height: 1416, description: <><Roles plan design develop test launch /></> },
{ src: "/img/screenshots/may-4th-5th.png", width: 1172, height: 2533, description: <><Roles plan design develop launch /></> },
{ src: "/img/screenshots/redbubble-shirts.png", width: 990, height: 1972, description: <><Roles design maintain /></> },



// { src: "/img/screenshots/tcec.jpg", width: 932, height: 4793, description: <><Roles plan design develop /></> },
// { src: "/img/screenshots/truconnect.png", width: 990, height: 3586, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/booking-ninja.png", width: 1320, height: 3433, description: <><Roles develop test /></> },
// { src: "/img/screenshots/my-ignite.png", width: 1251, height: 5818, description: <><Roles develop test launch maintain /></> },
// { src: "/img/screenshots/viridian-travel.jpg", width: 1300, height: 6243, description: <><Roles develop test launch maintain /></> },
// { src: "/img/screenshots/hobblitzelle.png", width: 990, height: 820, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/tsp-dashboard.png", width: 990, height: 640, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/music-for-marriage.png", width: 990, height: 640, description: <><Roles design /></> },
// { src: "/img/screenshots/time-trackr.jpg", width: 1800, height: 588, description: <><Roles plan design develop /></> },
// { src: "/img/screenshots/storybook.png", width: 1320, height: 1979, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/giving-kiosk.png", width: 990, height: 1092, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/kdpi.png", width: 640, height: 1350, description: <><Roles plan design develop test launch /></> },
// { src: "/img/screenshots/pay-half-club.jpg", width: 932, height: 4139, description: <><Roles plan design develop test launch maintain /></> },
// { src: "/img/screenshots/clean-nation.png", width: 1209, height: 976 },
// { src: "/img/screenshots/e4s-rep.png", width: 1000, height: 1596 },
// { src: "/img/screenshots/enlyten.png", width: 990, height: 640 },
// { src: "/img/screenshots/ecenter53.png", width: 1072, height: 880 },
// { src: "/img/screenshots/acn.png", width: 990, height: 6820 },
// { src: "/img/screenshots/hiview.png", width: 990, height: 747 },
// { src: "/img/screenshots/IconArtClub.jpg", width: 932, height: 3056 },
// { src: "/img/screenshots/texan-sun-power.png", width: 990, height: 4054  },
// { src: "/img/screenshots/okc-thunder.png", width: 990, height: 640 },
// { src: "/img/screenshots/poor-folk-bows.png", width: 990, height: 871  },
// { src: "/img/screenshots/poor-folk-bows-logos.png", width: 739, height: 985 },
// { src: "/img/screenshots/emergency-notification.png", width: 990, height: 1704 },
// { src: "/img/screenshots/logos.png", width: 990, height: 527 },
// { src: "/img/screenshots/ampegy-corp.png", width: 1104, height: 1000 },
// { src: "/img/screenshots/angeltree-signup.png", width: 1024, height: 1655 },
// { src: "/img/screenshots/evolve-rep.png", width: 1163, height: 1321 },
// { src: "/img/screenshots/iea-rep.png", width: 1121, height: 935 },
// { src: "/img/screenshots/liverpool.jpg", width: 1100, height: 820 },
// { src: "/img/screenshots/m-cares.png", width: 1203, height: 1130 },
// { src: "/img/screenshots/momentus.png", width: 1194, height: 946 },
// { src: "/img/screenshots/paycation.png", width: 1022, height: 1508 },
// { src: "/img/screenshots/primeaxs-marketing.jpg", width: 1200, height: 5178 },
// { src: "/img/screenshots/travel-light.jpg", width: 960, height: 5672 },
// { src: "/img/screenshots/traverus.jpg", width: 1000, height: 871 },
// { src: "/img/screenshots/viridian-rep.png", width: 1147, height: 1702 },


];
//'columns' | 'rows' | 'masonry'


export default function Gallery() {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <PhotoAlbum layout="columns" photos={photos} onClick={({ index }) => setIndex(index)} />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom, Captions]}
      />

    </>
  );
}