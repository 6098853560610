import React from "react";
import { Button } from "react-bootstrap";
import useColorScheme from "./useColorScheme";

export const DarkModeToggle = () => {
  const { isDark, setIsDark } = useColorScheme();
  return (
    <>
      <Button aria-label="Switch to Light Mode" variant="outline-light text-warning bg-white-hover" size="sm" className={`border-0 rounded-circle bi-stack-hover ${isDark ? "" : "d-none"}`} onClick={() => setIsDark(!isDark)}><i className="bi bi-sun"></i><i className="bi bi-sun-fill hover-on"></i></Button>
      <Button aria-label="Switch to Dark Mode" variant="outline-dark" size="sm" className={`border-0 rounded-circle bi-stack-hover ${isDark ? "d-none" : ""}`} onClick={() => setIsDark(!isDark)}><i className="bi bi-moon"></i><i className="bi bi-moon-fill hover-on"></i></Button>
    </>
  );
};