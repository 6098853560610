import React from 'react';
import { AndroidOriginal, AngularOriginal, AntdesignOriginal, AppleOriginal, AzuresqldatabaseOriginal, BitbucketOriginal, BootstrapOriginal, BrowserstackOriginal, CanvaOriginal, ChromeOriginal, CodepenOriginal, ConfluenceOriginal, CsharpOriginal, Css3Original, D3jsOriginal, DotNetPlain, DreamweaverOriginal, FigmaOriginal, FilezillaOriginal, FirefoxOriginal, GimpOriginal, GitOriginal, HandlebarsOriginal, Html5Original, Ie10Original, JavascriptOriginal, JestPlain, JiraOriginal, JqueryOriginal, JsonOriginal, LessPlainWordmark, MaterializecssOriginal, MaterialuiOriginal, MysqlOriginal, NpmOriginalWordmark, OperaOriginal, PhotoshopOriginal, PhpOriginal, PowershellOriginal, ReactOriginal, ReactbootstrapOriginal, SafariOriginal, SassOriginal, SketchOriginal, SlackOriginal, StackoverflowOriginal, StorybookOriginal, SwaggerOriginal, UbuntuOriginal, VisualbasicOriginal, VisualstudioOriginal, VscodeOriginal, WebpackOriginal, XdOriginal, XmlOriginal, YamlPlain, YarnOriginal, NodejsOriginal  } from 'devicons-react';



const ToolLogos = () => {
  return (
    <>

        <div className="slide fav"><img src="/img/tools/openai-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-bard-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-gemini-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Microsoft_365_Copilot_Icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Firefly_Logo.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/artificial-intelligence-ai-icon.svg" alt="" /></div>

        <div className="slide"><VisualbasicOriginal size="50" /></div>
        <div className="slide"><DotNetPlain size="50" /></div>
        <div className="slide"><CsharpOriginal size="50" /></div>

        <div className="slide fav"><Html5Original size="50" /></div>
        <div className="slide"><img src="/img/tools/markdown.svg" alt="" /></div>
        <div className="slide"><JsonOriginal size="50" /></div>
        <div className="slide"><XmlOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/svg.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/prettier.svg" alt="" /></div>

        <div className="slide fav"><Css3Original size="50" /></div>
        <div className="slide fav"><SassOriginal size="50" /></div>
        <div className="slide"><LessPlainWordmark size="50" /></div>
        <div className="slide"><img src="/img/tools/w3c.svg" alt="" /></div>

        <div className="slide"><img src="/img/tools/ajax.png" alt="" /></div>
        <div className="slide fav"><JavascriptOriginal size="50" /></div>
        <div className="slide fav"><JqueryOriginal size="50" /></div>        
        <div className="slide"><img src="/img/tools/jquery-ui.svg" alt="" /></div>
        <div className="slide"><HandlebarsOriginal size="50" fill="#666" /></div>
        <div className="slide"><PhpOriginal size="50" /></div>
        <div className="slide"><AngularOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/launchdarkly.svg" alt="" /></div>
        <div className="slide"><D3jsOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/font-awesome.svg" alt="" /></div>

        <div className="slide fav"><ReactOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/typescript-icon.svg" alt="" /></div>
        <div className="slide"><WebpackOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/gulp.png" alt="" /></div>
        <div className="slide"><NpmOriginalWordmark size="50" /></div>        
        <div className="slide"><NodejsOriginal size="50" /></div>
        <div className="slide"><YamlPlain size="50" /></div>
        <div className="slide"><YarnOriginal size="50" /></div>

        <div className="slide"><img src="/img/tools/NuGet_project_logo.svg" alt="" /></div>
        <div className="slide"><JestPlain size="50" /></div>
        <div className="slide"><StorybookOriginal size="50" /></div>
        <div className="slide"><SwaggerOriginal size="50" /></div>

        <div className="slide"><VisualstudioOriginal size="50" /></div>
        <div className="slide fav"><VscodeOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/atom-icon.svg" alt="" /></div>
        <div className="slide"><DreamweaverOriginal size="50" /></div>

        <div className="slide fav"><XdOriginal size="50" /></div>
        <div className="slide fav"><SketchOriginal size="50" /></div>
        <div className="slide fav"><img src="/img/tools/invision-icon.svg" alt="" /></div>
        <div className="slide fav"><FigmaOriginal size="50" /></div>

        <div className="slide fav"><PhotoshopOriginal size="50" /></div>
        <div className="slide fav"><img src="/img/tools/adobe-illustrator.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Acrobat_Reader_icon_(2020).svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Creative_Cloud_rainbow_icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Express_logo_CMYK_256px.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/adobe-lightroom.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Fresco_CC_icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/adobe-indesign.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Adobe_Audition_CC_icon_(2020).svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/Audacity_Logo.63b57726.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/MAGIX-SOUND-FORGE-Pro.png" alt="" /></div>

        <div className="slide"><GimpOriginal size="50" /></div>
        <div className="slide"><CanvaOriginal size="50" /></div>

        <div className="slide"><img src="/img/tools/logo-mssql-square.svg" alt="" /></div>
        <div className="slide"><MysqlOriginal size="50" /></div>
        <div className="slide"><AzuresqldatabaseOriginal size="50" /></div>

        <div className="slide"><img src="/img/tools/Microsoft_Office_Access_(2019-present).svg" alt="" /></div>
        <div className="slide fav"><img src="/img/tools/atlassian.svg" alt="" /></div>
        <div className="slide"><JiraOriginal size="50" /></div>
        <div className="slide"><ConfluenceOriginal size="50" /></div>
        <div className="slide"><PowershellOriginal size="50" /></div>
        <div className="slide"><GitOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/github-icon.svg" alt="" /></div>
        <div className="slide"><BitbucketOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/Amazon_Web_Services_Logo.svg" alt="" /></div>
        <div className="slide"><CodepenOriginal size="50" fill="#ffc107" /></div>
        <div className="slide"><img src="/img/tools/codesandbox-icon.svg" alt="" /></div>
        <div className="slide"><StackoverflowOriginal size="50" /></div>

        <div className="slide fav"><img src="/img/tools/icon_0004_google-search.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0007_google-drive.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0001_google-mail.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0005_google-cal.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0003_google-docs.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0002_google-sheets.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0000_google-slides.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-maps.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-developers.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-analytics.svg" alt="" /></div>
    
        <div className="slide"><img src="/img/tools/icon_0011_outlook.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0010_word.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0009_xls.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/icon_0008_ppt.png" alt="" /></div>
        <div className="slide"><img src="/img/tools/microsoft-onedrive.svg" alt="" /></div>


        <div className="slide"><FilezillaOriginal size="50" /></div>

        <div className="slide"><SlackOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/messenger.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/whatsapp-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/zoom-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/google-meet.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/skype.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/microsoft-teams.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/loom-icon.svg" alt="" /></div>

        <div className="slide fav"><img src="/img/tools/microsoft-icon.svg" alt="" /></div>
        <div className="slide fav"><AppleOriginal size="50" fill="#666" /></div>
        <div className="slide fav"><AndroidOriginal size="50" /></div>
        <div className="slide"><UbuntuOriginal size="50" /></div>

        <div className="slide fav"><BootstrapOriginal size="50" /></div>
        <div className="slide"><ReactbootstrapOriginal size="50" /></div>
        <div className="slide"><MaterializecssOriginal size="50" /></div>
        <div className="slide"><MaterialuiOriginal size="50" /></div>
        <div className="slide"><AntdesignOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/semantic-ui.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/uikit.svg" alt="" /></div>

        <div className="slide"><ChromeOriginal size="50" /></div>
        <div className="slide"><FirefoxOriginal size="50" /></div>
        <div className="slide"><img src="/img/tools/microsoft-edge.svg" alt="" /></div>
        <div className="slide"><Ie10Original size="50" /></div>
        <div className="slide"><SafariOriginal size="50" /></div>
        <div className="slide"><OperaOriginal size="50" /></div>
        <div className="slide"><BrowserstackOriginal size="50" /></div>

        <div className="slide"><img src="/img/tools/basecamp-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/aha.svg" alt="" /></div>    
        <div className="slide"><img src="/img/tools/meta-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/twitter-x-seeklogo-3.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/youtube-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/vimeo-icon.svg" alt="" /></div>
        <div className="slide"><img src="/img/tools/dropbox.svg" alt="" /></div>  
        <div className="slide"><img src="/img/tools/eventbrite-icon.svg" alt="" /></div>  
        <div className="slide"><img src="/img/tools/paypal.svg" alt="" /></div>

    </>
  );
};

export default ToolLogos;




