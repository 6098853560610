import React, { useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import {Image } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'wouter';
import { AdvancedBannerTop } from "../components/AdvancedBanner.tsx";
import HeroBlurb from '../components/HeroBlurb.jsx';
import LGLogo from '../components/LGLogo';
import "../styles/hero.scss";
import "../styles/home.scss";
import "../styles/infinite-logos.scss";
import BentoStats from '../components/BentoStats.jsx';
import ClientLogos from '../components/ClientLogos.jsx';
import ToolLogos from '../components/ToolLogos.jsx';
import AnimateProgressBar from '../components/AnimateProgressBar';
import { SkillsParallax } from "../components/SkillsParallax.tsx";
import { Slide } from "react-awesome-reveal";


const Home = () => {
  
  const [avatar, setAvatar] = useState(true);  
  const avatarHandler = () => { setAvatar(!avatar); };

  const [moreTools, showMoreTools] = useState(false);
  const moreToolsHandler = () => { showMoreTools(!moreTools); };

  const [moreClients, showMoreClients] = useState(false);
  const moreClientsHandler = () => { showMoreClients(!moreClients); };

  const angtft = (
    <Popover id="angtft">
      <Popover.Header as="h6">Oh, you DO have time for that?</Popover.Header>
      <Popover.Body>
        See an <a href="https://launchgirl.com/portfolio.asp" target="_blank" rel="noreferrer">archive of my portfolio items</a>, displaying 360 projects for 94 clients (the 20%). Note: This legacy page is not maintained.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div id="home">&nbsp;</div>
      <div className='mt-5'>
        <ParallaxProvider>
          <AdvancedBannerTop />
        </ParallaxProvider>
      </div>
      <div className="hero-stack">
        <Container fluid="lg">
            <HeroBlurb/>
        </Container>
      </div>

      <div id="launchgirl" className={`${avatar ? '' : 'taco'}`}>
        <Container fluid="lg">          
          <Row>

            <Col lg={7} className="align-self-center">
              <h2><LGLogo /></h2>
              <p>Howdy! I'm Tonya, a UI/UX Leader with over 25 years of experience in design, strategy, development, management, and awesomesauce.{/* In that time, I've done everything from creating simple logos to leading the development of enterprise design systems and managing agile projects and teams through the product design and software development life cycle.*/}</p>
              <p>I am currently seeking my next new adventure. If you would like to discuss a remote employment opportunity relevant to my current <a href="/pdfs/Tonya-Abna-Resume.pdf" target="_blank" rel="noreferrer" className="link-pdf">resume <img src="/img/icons/pdf.png" alt="pdf"/></a>, or would like to take me out for some tacos, please contact me via <a href="https://www.linkedin.com/in/tonya-abna/" target="_blank" rel="noreferrer" className="link-linkedin">LinkedIn</a> or <a href="mailto:tonya.abna+lg@gmail.com" target="_blank" rel="noreferrer">Email</a>.</p>
              <h3 className="mt-5">Why LaunchGirl?</h3>
              <p>Why not <span className="link" onClick={() => avatarHandler()}>LunchGirl</span>? Read more <Link href='/About'>about me</Link> to learn why. Discover the values I apply to my work, my management style, and what others have to say about working with me.</p>
              <div className="hide-dark"><Link href='/About'><Button variant="outline-danger">About Me</Button></Link></div>
              <div className="show-dark"><Link href='/About'><Button variant="outline-primary">About Me</Button></Link></div>
            </Col>

            <Col lg={5} className={`pt-5 pt-lg-0 align-self-center text-center ${avatar ? '' : 'taco'}`}>
              <div>
              <Slide direction="up">
              <div className="flex-fill avatar transition-500"></div>
              <div className="tagline-cat">
                <Image src="/img/home/tacocat01.png" alt="" rounded fluid className="tacocat" />
                <h3 className="h6 mb-1 mt-3 transition-500 cursor-pointer" onClick={() => avatarHandler()}><strong>Launch</strong>Girl</h3>
                <small className="text-uppercase letter-spacing transition-500 d-block"><span className="text-nowrap">Herder of cats,</span> <span className="text-nowrap">launcher of wow</span></small>
              </div>
              <div className="tagline-taco">
                <Image src="/img/home/tacocat02.png" alt="" rounded fluid className="tacocat" />
                <h3 className="h6 mb-1 mt-3 transition-500 cursor-pointer" onClick={() => avatarHandler()}><strong>Lunch</strong>Girl</h3>
                <small className="text-uppercase letter-spacing transition-500 d-block"><span className="text-nowrap">Goddess of potlucks,</span> <span className="text-nowrap">bringer of tacos</span></small>
              </div>
              </Slide>
              </div>
            </Col>

          </Row>
        </Container>
      </div>

      <div id="mywork">
        <Container fluid="lg">
          <BentoStats/>
        </Container>
      </div>

      <div id="clients">
        <div className="m-3 mb-0">
          <Row>
            <Col sm={6} className="text-center text-sm-start">
              <span className={`${moreClients ? '' : 'd-none'}`}>My Client List</span><span className={`${moreClients ? 'd-none' : ''}`}>My Top Clients</span>
            </Col>
            <Col sm={6} className="text-center text-sm-end">
              <div onClick={() => moreClientsHandler()} className="link">Show <span className={`${moreClients ? 'd-none' : ''}`}>more clients</span> <span className={`${moreClients ? '' : 'd-none'}`}>top clients only</span></div>
            </Col>
          </Row>
        </div>

        <div className={`slider ${moreClients ? 'brands' : 'bmoc'}`}>
          <div className="slide-track">
            <ClientLogos />
            <ClientLogos />
          </div>
        </div>
      </div>

      <div id="archive">
        <Container fluid="lg">
          <Row>
            <Col md={{ span: 7, offset: 5}} xl={{ span: 6, offset: 6}} className="colB">
              <div>
                <h2>Want to see my <em>full</em> portfolio?</h2>
                <p className="mb-0">Well, you can't.</p>
                <p className="pblurb">(Although there's a link to a few select visuals and case studies below.)</p>
                <p>Here's why:</p>

                <div className="d-flex">
                  <div className="pbar pbar1">
                    <AnimateProgressBar>
                      <div></div>
                    </AnimateProgressBar>
                  </div>
                  <div className="plabel"><strong>26%</strong> &middot; Can't Show</div>
                </div>
                <div className="pblurb mb-3">Super secret proprietary innovations that I can't exhibit.</div>

                <div className="d-flex">
                  <div className="pbar pbar2">
                  <AnimateProgressBar>
                    <div></div>
                  </AnimateProgressBar>
                </div>
                  <div className="plabel"><strong>38%</strong> &middot; Won't Show</div>
                </div>
                <div className="pblurb mb-3">So old that I'd be embarrassed to show it.</div>
                
                <div className="d-flex">
                  <div className="pbar pbar3">
                  <AnimateProgressBar>
                    <div></div>
                  </AnimateProgressBar>
                </div>
                  <div className="plabel"><strong>15%</strong> &middot; Don't Have</div>
                </div>
                <div className="pblurb mb-3">Didn't get around to taking screenshots, or haven't had time to add online.</div>
                
                <div className="d-flex">
                  <div className="pbar pbar4">
                  <AnimateProgressBar>
                    <div></div>
                  </AnimateProgressBar>
                </div>
                  <div className="plabel"><strong>20%</strong> &middot; Shouldn't Show</div>
                </div>
                <div className="pblurb mb-3">
                  A wise mentor told me even this is 'a bit much'. {''}
                  <OverlayTrigger 
                    trigger="click" 
                    placement="top" 
                    overlay={angtft}
                    rootClose
                  >
                    <em className="link">Ain't nobody got time for that.</em>
                  </OverlayTrigger>
                </div>

                <div className="d-flex">
                  <div className="pbar pbar5">
                  <AnimateProgressBar>
                    <div></div>
                  </AnimateProgressBar>
                </div>
                  <div className="plabel"><strong>1%</strong> &middot; <Link href="/Portfolio">Ok, you can see these few &raquo;</Link></div>
                </div>
                <div className="pblurb mb-3">A sampling of my designs.</div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="showcase">
        <ParallaxProvider>
          <SkillsParallax />
        </ParallaxProvider>
      </div>

      <div id="tools">
        <div className="m-3 mb-0">
          <Row>
            <Col sm={6} className="text-center text-sm-start">
              My <span className={`${moreTools ? '' : 'd-none'}`}>Tool Shed</span><span className={`${moreTools ? 'd-none' : ''}`}>Tool Box</span>
            </Col>
            <Col sm={6} className="text-center text-sm-end">
              <div onClick={() => moreToolsHandler()} className="link">Show <span className={`${moreTools ? 'd-none' : ''}`}>more tools used</span> <span className={`${moreTools ? '' : 'd-none'}`}>top tools only</span></div>
            </Col>
          </Row>
        </div>

        <div className={`slider ${moreTools ? '' : 'favs'}`}>
          <div className="slide-track">
            <ToolLogos />
            <ToolLogos />
          </div>
        </div>
        
      </div>

    </>
  );
};

export default Home;