import React from 'react';

const ContactIcons = () => {
  return (
    <span>
        <a href="https://www.linkedin.com/in/tonya-abna/" target="_blank" rel="noreferrer" className="sm-icon" aria-label="LinkedIn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path d="M4.9 13.4V6.2H2.5v7.2h2.4zM3.7 5.2c.8 0 1.4-.6 1.4-1.2 0-.7-.5-1.2-1.3-1.2s-1.4.4-1.4 1.1.5 1.3 1.3 1.3h0zm5 8.2v-4c0-.2 0-.4.1-.6.1-.5.5-.9 1.2-.9.9 0 1.2.7 1.2 1.6v3.9h2.4V9.2C13.6 7 12.4 6 10.8 6 9.5 6 9 6.7 8.7 7.2h0 0 0v-1H6.3v7.2h2.4z"/></svg>
        </a> 
        <a href="mailto:tonya.abna+lg@gmail.com" target="_blank" rel="noreferrer" className="sm-icon" aria-label="Email">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path d="M14.1 6.7c0-1.3-.5-2.4-1.6-3.2-1-.8-2.3-1.2-3.7-1.2s-2.6.3-3.7.9c-1 .6-1.8 1.3-2.4 2.2s-.9 2-.9 3.1c0 1 .3 2 .8 2.8 1 1.6 2.7 2.4 5 2.4 1.7 0 3.1-.5 4.4-1.5.1-.2.1-.4.2-.5l-.2-.2c-1.2 1-2.6 1.6-4.1 1.6-1.4 0-2.5-.5-3.3-1.4-.7-.8-1.1-1.9-1.1-3.2 0-1 .2-1.9.7-2.8s1.1-1.6 2-2.1 1.7-.7 2.7-.7 1.7.2 2.3.5c1.2.7 1.7 1.8 1.7 3.3 0 .8-.1 1.6-.4 2.2-.2.4-.4.8-.7 1.1-.4.4-.7.6-1.1.6-.3 0-.5-.2-.5-.7 0-.4.1-1 .3-2s.4-2.1.8-3.2l-.2-.2-1.2.2-.1.4c-.3-.4-.7-.6-1.3-.6-1.1 0-2 .4-2.7 1.3-.7.8-1 1.8-1 2.9 0 .7.2 1.3.5 1.7.4.4.9.6 1.6.6s1.4-.5 2-1.4c0 .6.1 1 .4 1.2a1.69 1.69 0 0 0 1 .3c.7 0 1.4-.3 1.9-.7 1.3-.9 1.9-2.2 1.9-3.7zM9.4 6c0 .4-.1.8-.2 1.3-.3 1.2-.6 2-1 2.4-.3.4-.6.5-.9.5-.2 0-.4-.1-.6-.3s-.2-.6-.2-1.2.1-1.2.4-1.7c.5-1.3 1-2 1.7-2 .5 0 .8.4.8 1z"/></svg>
        </a> 
        <a href="/pdfs/Tonya-Abna-Resume.pdf" target="_blank" rel="noreferrer" className="sm-icon" aria-label="Resume PDF">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path d="M14.8 9.7c-1-1.1-3.9-.6-4.6-.6-1-1-1.7-2.1-1.9-2.5a9.6 9.6 0 0 0 .6-3.3c0-1-.4-2.1-1.5-2.1a1.1 1.1 0 0 0-1 .6c-.4.8-.2 2.5.5 4.2-.4 1.2-.8 2.5-2 4.6-1.2.5-3.6 1.6-3.8 2.8-.1.4 0 .7.3 1 .3.2.6.4 1 .4 1.5 0 2.9-2.1 3.9-3.8 1.1-.4 2.3-.7 3.5-.9 1.6 1.4 2.9 1.6 3.7 1.6 1 0 1.3-.4 1.4-.8.3-.4.2-.8-.1-1.2zM2.2 14H2c-.1-.1-.2-.2-.1-.4.1-.6 1.1-1.3 2.7-2.1-.9 1.6-1.8 2.5-2.4 2.5zM7.1 2.1c.1-.1.2-.2.4-.2.3 0 .4.4.4.8-.1.9-.2 1.7-.5 2.5-.6-1.6-.5-2.7-.3-3.1zM7 9.9c.3-.6.8-1.7.9-2.2.4.6 1 1.3 1.3 1.6L7 9.9zm5.9.8c-.8-.2-1.5-.5-2.1-1 .5 0 1.7-.2 2.5 0 .3.1.6.3.6.6s-.4.6-1 .4z"/></svg>
        </a>
        <a href="https://www.redbubble.com/people/launchgirl/shop" target="_blank" rel="noreferrer" className="sm-icon" aria-label="Redbubble Store">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M11.9 12.2h-3c-.1 0-.3-.1-.3-.2V4.3c0-.2.2-.3.3-.3h2.8c2.1 0 2.6 1.2 2.6 2.3 0 .6-.2 1.1-.5 1.4.8.3 1.2 1 1.2 2.1 0 1.5-1.1 2.4-3.1 2.4m-4.3 0H1.3c-.1 0-.3-.1-.3-.2V4.3c0-.2.1-.3.2-.3h2.9C6 4 7.2 5 7.2 6.7c0 1.1-.6 2-1.4 2.3l2.1 2.7c.1.1.1.3-.1.4h-.2" fill="currentColor"/></svg>
        </a>
    </span>
  );
};

export default ContactIcons;
