import React from 'react';
import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";
import HeroLead from './HeroLead';
import AboutBlurb from './AboutBlurb';

export const AboutBanner = () => {

  const fg: BannerLayer = {
    shouldAlwaysCompleteAnimation: true,
    translateY: [3, -5],
    children: (
      <>
        <div className="about-image bg-sparkle1 animate-flicker1"></div>
        <div className="about-image bg-sparkle2 animate-flicker2"></div>
        <div className="about-image"></div>
      </>
    )
  };

  const hd1: BannerLayer = {
    translateY: [0, 50],
    scale: [1, 1.05, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="about-content about-content-1 position-absolute d-flex align-items-center justify-content-center mt-5">
        <div>
          <HeroLead/>
          <AboutBlurb/>
        </div>
      </div>
    )
  };
  
  const hd2: BannerLayer = {
    translateY: [0, 50],
    opacity: [1, -1, "easeIn"],
    scale: [1, 1.05, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="about-content about-content-2 position-absolute d-flex align-items-center justify-content-center mt-5">
        <div aria-hidden="true">
          <HeroLead/>
          <AboutBlurb/>
        </div>
      </div>
    )
  };

  return (
    <ParallaxBanner
      layers={[fg, hd1, hd2]}
      className="about-container"
    />
  );
};
