// The main navigation in the header of the page
import React from 'react';
import { DarkModeToggle } from './DarkModeToggle';
import { Navbar, Nav, Container } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
import { Link, useRoute } from 'wouter';
import LGLogo from './LGLogo';

const ActiveLink = props => {
  const [isActive] = useRoute(props.href);
  return (
    <Link {...props} className={isActive ? "active nav-link" : "nav-link"}>{props.children}</Link>
  );
};

class Header extends React.Component {

  // setting up header properties and states
  constructor(props) {
    super(props);

    // the header state
    this.state = { 
      expanded: false,               // the state of whether or not the navbar is expanded
      useDarkMode: false            // the state of whether or not dark mode is being used
    };

    // what to do when dark mode is toggled
    this.handleDarkModeToggle = this.handleDarkModeToggle.bind(this);
    
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }

  handleMenuToggle() {
    this.setState({ expanded: !this.state.expanded }); 
  }

  // what to do when dark mode is toggled
	handleDarkModeToggle() {
    // after click, immediately lose focus so that spacebar keypress won't trigger a button click
    this.switchDM.blur();
    // toggle the menu open state
    if (this.state.useDarkMode) {// if currently using dark mode
      this.setState({ useDarkMode: !this.state.useDarkMode }); // toggle the state
      document.documentElement.setAttribute('data-bs-theme', 'light'); 
    }
    else {// else if currently using light mode
      this.setState({ useDarkMode: !this.state.useDarkMode }); // toggle the state
      document.documentElement.setAttribute('data-bs-theme', 'dark'); 
    }
  }


  // show the header
  render() {
     return (
      <header className='lg'>
        <Navbar id="main-nav" variant="light" bg="glow" expand="md" className='fixed-top p-0' expanded={this.state.expanded} onToggle={this.handleMenuToggle}>
          <Container fluid>
            <Navbar.Brand>
              <div className="d-inline-block align-middle me-2" aria-label="LaunchGirl">
                <LGLogo />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="main-navbar-nav">
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="main-navbar-nav">
              <Nav className='flex-fill mt-1'>
                <ActiveLink href='/' onClick={this.handleMenuToggle}>Home</ActiveLink>
                <ActiveLink href='/About' onClick={this.handleMenuToggle}>About</ActiveLink>
                <ActiveLink href='/Portfolio' onClick={this.handleMenuToggle}>Portfolio</ActiveLink>
                <ActiveLink href='/CaseStudies' onClick={this.handleMenuToggle}>Case Studies</ActiveLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className='dm-toggle fixed-top'><DarkModeToggle /></div>
      </header>
     );
  }
}
//export this component for use on the main page
export default Header;