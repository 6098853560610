import React from 'react';
import { Switch, Route } from 'wouter';
import './App.scss';
import 'animate.css';
import Home from './routes/Home';
import About from './routes/About';
import Portfolio from './routes/Portfolio';
import CaseStudies from './routes/CaseStudies';
import KitchenSink from './components/KitchenSink/KitchenSink';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

export const App = () => (
  <>
    <ScrollToTop />
    <Header />
    <Switch>
      <Route path="/" component={Home} />
      <Route path="/About" component={About} />
      <Route path="/Portfolio" component={Portfolio} />
      <Route path="/CaseStudies" component={CaseStudies} />
      <Route path="/KitchenSink" component={KitchenSink} />
    </Switch>
    <Footer />
  </>

);