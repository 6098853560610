import React from 'react';
import LGIcon from './LGIcon';

const HeroLead = () => {
  return  <div className="letter-spacing mb-3 lead text-uppercase" aria-hidden="true">
            <span className="text-nowrap hero-name">Tonya Abna</span> <LGIcon/> <span className="text-nowrap hero-title">UI/UX Leader</span>
          </div>;
};

export default HeroLead;
