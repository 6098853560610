import React from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Stat from '../components/Stat.jsx';
import '../styles/bento.scss';

const BentoStats = ({ label, value }) => {
    return (
        <>
        <Row className="align-items-stretch">

            <Col lg={3}>              
              <h2>My Work</h2>
              <p>In my career I have worked on an extensive number of projects of various types, whether that involved end-to-end design and development, or just simple maintenance.</p>
            </Col>

            <Col lg={9} className="text-center">

              <Row className="h-100 align-items-stretch bento">

                <Col sm={6}>
                  <Row className="h-66 align-items-stretch">
                    <Col xs={12} className="d-flex stat-lg"><Stat label="websites &amp; applications" value={626} delay={50} /></Col>
                  </Row>
                  <Row className="h-34 align-items-stretch">
                    <Col xs={6} className="d-flex"><Stat label="graphics &amp; multimedia" value={109} delay={200} /></Col>
                    <Col xs={6} className="d-flex"><Stat label="print &amp; publications" value={108}  delay={350}/></Col>
                  </Row>
                </Col>

                <Col sm={6}>
                  <Row className="h-33 align-items-stretch">
                    <Col className="d-flex stat-md"><Stat label="screenprint designs" value={350} delay={500} /> </Col>   
                  </Row>   
                  <Row className="h-33 align-items-stretch">
                    <Col xs={6} className="d-flex"><Stat label="logos &amp; branding" value={75} delay={650} /></Col>
                    <Col xs={6} className="d-flex"><Stat label="responsive or mobile apps" value={100} delay={800} /></Col>
                  </Row>
                  <Row className="h-34 align-items-stretch">
                    <Col className="d-flex stat-md"><Stat label="marketing &amp; event materials" value={354} delay={950} /> </Col>   
                  </Row>                  
                </Col>

              </Row>

            </Col>

        </Row>
        </>
    );
};

export default BentoStats;
