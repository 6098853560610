import React, { useState, useEffect } from 'react';
import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";
import HeroLead from './HeroLead';
import HeroBlurb from './HeroBlurb';

export const AdvancedBannerTop = () => {

  const foreground: BannerLayer = {
    shouldAlwaysCompleteAnimation: true,
    scale: [1.5, 1.25, "easeOutCubic"],
    children: (
      <>
        <div className="hero-image bg-sparkle1 animate-flicker1"></div>
        <div className="hero-image bg-sparkle2 animate-flicker2"></div>
        <div className="hero-image"></div>
      </>
    )
  };

  const overlay: BannerLayer = {
    children: (
      <div className="hero-overlay"></div>
    )
  };

  const headline1: BannerLayer = {
    translateY: [0, 50],
    scale: [1, 1.05, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="hero-content hero-content-1 position-absolute d-flex align-items-center justify-content-center mt-5">
        <div>
          <HeroLead/>
          <HeroBlurb/>
        </div>
      </div>
    )
  };
  
  const headline2: BannerLayer = {
    translateY: [0, 50],
    opacity: [1, -1, "easeIn"],
    scale: [1, 1.05, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="hero-content hero-content-2 position-absolute d-flex align-items-center justify-content-center mt-5">
        <div aria-hidden="true">
          <HeroLead/>
          <HeroBlurb/>
        </div>
      </div>
    )
  };

  return (
    <ParallaxBanner
      layers={[foreground, overlay, headline1, headline2]}
      className="hero-container"
    />
  );
};
