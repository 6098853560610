import React from 'react';

const PortfolioBlurb = () => {
  return  <div className="portfolio-blurb">
            <h1><em>User Experience</em> is my <span className="text-nowrap">kung fu</span>.</h1>
            <p className="mt-3 mb-0">And my <span className="text-nowrap">kung fu</span> is strong.</p>
          </div>;
};

export default PortfolioBlurb;
